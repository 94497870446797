import React, { useState } from "react";
import ResponseContent from "../components/organisms/ResponseContent";
import SearchBar from "../components/molecules/SearchBar";
import SearchHeader from "../components/atoms/SearchHeader";
import UserFeedback from "../components/organisms/UserFeedback";
import { useSearch } from "../context/SearchContext";
import { AngryFaceIcon } from "../assets/AngryFaceIcon";
import { HappyFaceIcon } from "../assets/HappyFaceIcon";
import { NeutralFaceIcon } from "../assets/NeutralFaceIcon";
import CustomAlert from "../components/atoms/CustomAlert";
import { sendUserFeedbackSelected } from "../utils/analyticsEvents";
import { JsonView, allExpanded, defaultStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";

const ResponsePage: React.FC = () => {
  const {
    handleSearch,
    currentSearchTerm,
    textResponse,
    isLoading,
    sources,
    banners,
    duration,
    requestId,
    contextId,
    jsonResponse,
  } = useSearch();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let parsedJson: any;

  const showJsonOutput = import.meta.env.VITE_ENV == "dev";

  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState<
    "angry" | "neutral" | "happy" | null
  >(null);
  const [isFeedbackSent, setIsFeedbackSent] = useState(false);

  const handleRefreshClick = () => {
    handleSearch(currentSearchTerm, "refresh");
  };

  const handleFeedbackClick = (feedback: "angry" | "neutral" | "happy") => {
    setSelectedFeedback(feedback);
    setIsFeedbackOpen(true);
    sendUserFeedbackSelected(requestId, currentSearchTerm, feedback, contextId);
  };

  const handleFeedbackClose = () => {
    setIsFeedbackOpen(false);
  };

  const handleFeedbackChange = (
    feedback: "angry" | "neutral" | "happy" | null,
  ) => {
    setSelectedFeedback(feedback);
  };

  try {
    parsedJson = JSON.parse(jsonResponse);
  } catch (error) {
    console.error("Error parsing JSON response:", error);
    parsedJson = { error: "Invalid JSON format" }; // Fallback content
  }

  return (
    <div className="flex flex-col w-full min-h-screen relative items-center">
      <div className="flex-grow flex w-full flex-col max-w-2xl px-6 justify-start pb-40 my-16 space-y-8">
        <div className="flex flex-col items-center space-y-4">
          <div className="text-BCP-dark-gray text-[16px] font-[400]">
            Resultados de búsqueda para
          </div>
          <SearchHeader>&quot;{currentSearchTerm}&quot;</SearchHeader>
        </div>

        <ResponseContent
          textResponse={textResponse}
          sources={sources}
          banners={banners}
          isLoading={isLoading}
          handleRefreshClick={handleRefreshClick}
          query={currentSearchTerm}
          requestId={requestId}
          contextId={contextId}
        />

        {isLoading && (
          <div className="flex justify-center items-center mx-auto max-w-[4rem] lg:my-48">
            <img src="simpleSpinner.gif" alt="loading" />
          </div>
        )}

        {!isLoading && !isFeedbackSent && (
          <div className="flex flex-col items-center space-y-4">
            <h3 className=" text-BCP-dark-blue text-[16px]">
              ¿Te fue útil esta información?
            </h3>
            <div className="flex space-x-4">
              <div onClick={() => handleFeedbackClick("angry")}>
                <AngryFaceIcon selected={selectedFeedback === "angry"} />
              </div>
              <div onClick={() => handleFeedbackClick("neutral")}>
                <NeutralFaceIcon selected={selectedFeedback === "neutral"} />
              </div>
              <div onClick={() => handleFeedbackClick("happy")}>
                <HappyFaceIcon selected={selectedFeedback === "happy"} />
              </div>
            </div>
          </div>
        )}
        <div className="w-full flex justify-center ">
          {isFeedbackSent && (
            <CustomAlert message="Entendido, ¡Muchas gracias por tu feedback!" />
          )}
        </div>

        {showJsonOutput && (
          <div>
            <JsonView
              data={parsedJson}
              shouldExpandNode={allExpanded}
              style={defaultStyles}
            />
          </div>
        )}
      </div>

      <UserFeedback
        duration={duration}
        textResponse={textResponse}
        sources={sources}
        requestId={requestId}
        contextId={contextId}
        query={currentSearchTerm}
        isOpen={isFeedbackOpen}
        onClose={handleFeedbackClose}
        selectedFeedback={selectedFeedback}
        setSelectedFeedback={setSelectedFeedback}
        onFeedbackChange={handleFeedbackChange}
        isFeedbackSent={isFeedbackSent}
        setIsFeedbackSent={setIsFeedbackSent}
      />

      <div className="fixed bottom-0 w-full z-30">
        <div className="max-w-[1200px] pt-6 pb-8 px-6 bg-white min-[1200px]:rounded-[24px] mx-auto">
          <div className="max-w-[604px] mx-auto">
            <SearchBar
              queryDisplayMode="responsePage"
              isInternalSearch={true}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponsePage;
