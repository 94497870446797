import { PoweredByAIFindr } from "../assets/PoweredByAIFindr";

export const LoadingPage = () => {
  return (
    <div className="w-full h-screen flex justify-center items-center p-10">
      <span className="animate-pulse font-black select-none ">
        <PoweredByAIFindr width="198" height="127" />
      </span>
    </div>
  );
};
