import React from "react";
import ReactMarkdown from "react-markdown";
import { Banner, Source } from "../../services/PipelineService";
import CustomTooltip from "../molecules/CustomTooltip";
import {
  sendResponseLinkClick,
  sendSourceLinkClick,
} from "../../utils/analyticsEvents";
import { BannerComponent } from "../molecules/Banner";

interface ResponseContentProps {
  textResponse: string;
  sources: Source[];
  banners: Banner[];
  isLoading?: boolean;
  handleRefreshClick: () => void;
  query: string;
  requestId: string;
  contextId: string;
}

const removeSpecificParams = (url: URL, setRfidValue: string): string => {
  const paramsToRemove = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "pcid",
    "rfid",
  ];

  paramsToRemove.forEach((param) => {
    url.searchParams.delete(param);
  });

  url.searchParams.set("rfid", setRfidValue);

  url.search = url.search.replace(/%3A/g, ":");

  return url.toString();
};

const ResponseContent: React.FC<ResponseContentProps> = ({
  textResponse,
  sources,
  banners,
  isLoading,
  handleRefreshClick,
  query,
  requestId,
  contextId,
}) => {
  return (
    <div className="flex w-full justify-center">
      <div className="max-w-2xl">
        <ReactMarkdown
          className="prose mx-auto font-[400] text-[16px] text-BCP-normal-text"
          components={{
            a: ({ href, ...props }) => {
              let modifiedHref = href;

              if (href) {
                const url = new URL(href);
                removeSpecificParams(url, "buscador:respuesta:pilotoIA");
                modifiedHref = url.toString();
              }

              return (
                <a
                  {...props}
                  href={modifiedHref}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    sendResponseLinkClick(
                      query,
                      requestId,
                      modifiedHref!,
                      props.children?.toString() || "",
                      contextId,
                    )
                  }
                >
                  {props.children}
                </a>
              );
            },
          }}
        >
          {textResponse}
        </ReactMarkdown>

        {banners && banners.length > 0 && (
          <div className="space-y-4 mt-4">
            {banners.map((banner, index) => (
              <BannerComponent key={index} banner={banner} />
            ))}
          </div>
        )}

        <div className="pt-8 text-sm text-BCP-dark-gray space-x-1 space-between flex items-center justify-between">
          <span className="flex items-center space-x-2">
            <a
              href="https://aifindr.ai/comofuncionaaifindr"
              target="_blank"
              rel="noopener noreferrer"
              className="pt-0.5"
            >
              <i className="pi pi-info-circle hover:text-BCP-gray-border transition-colors" />
            </a>
            <span>
              Respuesta generada con IA. Puede contener errores, comprueba las
              fuentes.
            </span>
          </span>

          {!isLoading && (
            <CustomTooltip text="Regenerar respuesta">
              <i
                onClick={handleRefreshClick}
                className="pi pi-refresh cursor-pointer hover:text-BCP-gray-border transition-colors"
              ></i>
            </CustomTooltip>
          )}
        </div>

        {sources.length > 0 && (
          <div className="mt-12 mb-4  text-[14px] text-BCP-normal-text uppercase">
            Fuentes
          </div>
        )}
        {sources
          .filter(
            (source, index, self) =>
              index === self.findIndex((e) => e.url === source.url),
          )
          .slice(0, 5)
          .map((result, index) => {
            const url = new URL(result.url);
            removeSpecificParams(url, "buscador:fuente:pilotoIA");

            return (
              <a
                key={index}
                href={url.toString()}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  sendSourceLinkClick(
                    query,
                    requestId,
                    url.toString(),
                    result.title,
                    contextId,
                  )
                }
              >
                <div className="items-center py-1 hover:underline space-x-2 text-[16px]  text-BCP-orange">
                  <span>{result.title}</span>
                  <i className="pi text-sm pi-arrow-right" />
                </div>
              </a>
            );
          })}
      </div>
    </div>
  );
};

export default ResponseContent;
