import React, { useState } from "react";
import RoundedButton from "../atoms/RoundedButton";
import { Banner } from "../../services/PipelineService";

interface BannerComponentProps {
  banner: Banner;
}

export const BannerComponent: React.FC<BannerComponentProps> = ({ banner }) => {
  const [isImageValid, setIsImageValid] = useState(true);

  const onButtonClick = () => {
    if (banner.buttonHref) {
      window.open(banner.buttonHref, "_blank");
    }
  };

  const handleImageError = () => {
    setIsImageValid(false);
  };

  return (
    <div className="bg-BCP-light-blue flex space-x-8 justify-center items-center max-w-[800px] mx-auto md:px-8 rounded-[16px]">
      {isImageValid && banner.imgUrl && (
        <div className="flex-shrink-0">
          <img
            src={banner.imgUrl}
            alt="Banner Image"
            className="w-[120px] h-[120px]"
            onError={handleImageError}
          />
        </div>
      )}
      <div className="space-y-2 md:space-x-8 md:items-center flex flex-col md:flex-row py-4">
        <div>
          <h3 className="font-bold text-[16px]">{banner.title}</h3>
          <h4 className="text-[12px] md:text-[14px]">{banner.description}</h4>
        </div>
        <span className="md:min-w-[130px] max-w-[200px] min-w-[100px]">
          <RoundedButton
            onClick={onButtonClick}
            label={banner.buttonTitle}
            customClass="text-[14px] leading-tight py-1 md:text-[14px] min-h-[32px] md:min-h-[30px]"
          />
        </span>
      </div>
    </div>
  );
};
